td, th {
    padding: 8px 8px 8px 15px;
}

thead tr {
    border-bottom: 2px solid #d3d3d3;
}

tbody tr {
    border-bottom: 1px solid #d3d3d3;
}

td div {
    margin-top: 4px;
}

td span {
    margin-left: 3px;
}