.dependency-groups h4 {
  line-height: 1.4;
}

.dependency-groups h4 > span {
  border-bottom: 1px solid #d8d8d8;
  margin-bottom: 3px;
}

.dependency-group li span {
  color: #666;
}