.display-package .package-icon {
  margin-top: 15px;
}

.display-package .package-title {
  word-break: break-word;
  overflow-wrap: break-word;
}

.display-package h1 small {
  margin-left: 10px;
  font-size: 45%;
}

.display-package .package-description {
  white-space: pre-wrap;
}

.display-package .package-release-notes {
  white-space: pre-wrap;
}

.package-details-info {
  margin-top: -25px;
}

.package-details-info .ms-Icon-ul li {
  margin-bottom: 15px;
}

.display-package .expandable-section h2 button {
  color: #000;
  text-decoration: none;
}

.display-package .expandable-section h2 .ms-Icon {
  font-size: 0.6em;
  position: relative;
  top: -2px;
  margin-right: 5px;
}
